// react core
import { useContext } from "react";

// date handling
import dayjs from "dayjs";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode as iconTickets } from "@fortawesome/pro-solid-svg-icons";
import { faUser as iconTicks } from "@fortawesome/pro-solid-svg-icons";
import { faUsers as iconCapacity } from "@fortawesome/pro-solid-svg-icons";
import { faBurst as iconLaunched } from "@fortawesome/pro-solid-svg-icons";
import { faBurst as iconLaunchedBorder } from "@fortawesome/pro-thin-svg-icons";
import { faCheckSquare as iconRunnerOffer } from "@fortawesome/pro-solid-svg-icons";
import { faSquareExclamation as iconRunnerNoOffer } from "@fortawesome/pro-solid-svg-icons";
import { faExclamationCircle as iconRunnerLaunchable } from "@fortawesome/pro-solid-svg-icons";
import { faRocketLaunch as iconRunnerLaunched } from "@fortawesome/pro-solid-svg-icons";
import { faRocketLaunch as iconTicking } from "@fortawesome/pro-solid-svg-icons";
import { faRocketLaunch as iconTickingBorder } from "@fortawesome/pro-thin-svg-icons";
import { faChevronRight as iconSignRight } from "@fortawesome/pro-duotone-svg-icons";
import { faBatteryEmpty as iconExpired } from "@fortawesome/pro-duotone-svg-icons";
import { orange } from "@mui/material/colors";

// entzy config
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";

export const DateTickingHeader = (props) => {
  const eventView = props.eventView;
  const mainContext = useContext(MainContext);
  const plural = props.date.ticking.Quantity === 1 ? "" : "s";
  return (
    <Box
      className="box-default"
      sx={{
        pb: configEntzy.APP_SPACING_SM,
        color: props.date.isExpired
          ? "#999"
          : props.launched
          ? "orange"
          : "white",
      }}
    >
      {props.runners ? (
        <Typography
          variant="subtitle2"
          noWrap={true}
          sx={{ textAlign: "left" }}
        >
          {props.eventView ? (
            <span
              style={{
                p: "2px",
                borderRadius: "2px",
                backgroundColor: "orange",
                color: "black",
                position:
                  eventView &&
                  mainContext.state.viewer.trail.calendarCompactView
                    ? "absolute"
                    : "relative",
              }}
            >
              <strong>&nbsp;OFFERS&nbsp;</strong>
            </span>
          ) : (
            <span>
              {props.date.day} {props.date.month} {props.date.year}
              <span className="content-disabled">&nbsp;|&nbsp;</span>
              {props.date.dow}
            </span>
          )}
          <span>&nbsp;</span>
        </Typography>
      ) : (
        <Typography
          variant="subtitle2"
          noWrap={true}
          sx={{ textAlign: "right" }}
        >
          {eventView && mainContext.state.viewer.trail.calendarCompactView ? (
            <span>&nbsp;</span>
          ) : (
            <span>
              <span
                style={{
                  float: "left",
                }}
              >
                &nbsp;Riders
              </span>
              <span>
                <FontAwesomeIcon
                  icon={iconTicks}
                  transform="left-5"
                  fixedWidth
                />
                {props.date.ticking.Quantity}
                {!props.launched &&
                  !props.launchPerTicket &&
                  " offer" + plural + " /" + props.triggers.default}
                {props.launched &&
                  !props.launchPerTicket &&
                  " ticket" + plural + " /" + props.limits.capacity}
                {/* ---- text equivalent but when event is set to launching per ticket ---- */}
                {!props.launched &&
                  props.launchPerTicket &&
                  " offered" +
                    (props.date.progress.tickets.leading.quantity > 0 &&
                    props.date.progress.tickets.leading.quantity !==
                      props.date.ticking.Quantity
                      ? " | " +
                        props.date.progress.tickets.leading.quantity +
                        " leading"
                      : "")}
                {props.launched &&
                  props.launchPerTicket &&
                  " ticket" + plural + " /" + props.limits.capacity}
              </span>
            </span>
          )}
        </Typography>
      )}
    </Box>
  );
};

export const DateTickingProgressBar = (props) => {
  const mainContext = useContext(MainContext);
  const eventView = props.eventView;
  return (
    <Box
      className="box-default"
      sx={{
        pt: configEntzy.APP_SPACING_MD,
        // pb: configEntzy.APP_SPACING_LG,
        // minHeight: configEntzy.AVATAR_CONTAINER_MD,
        borderTop: "1px solid",
        borderColor: "#444",
      }}
    >
      {props.launched ? (
        <Box className="box-default">
          {props.runners ? (
            <Box
              className="box-default text-left"
              sx={{
                height: configEntzy.APP_SPACING_LG,
                pl: configEntzy.APP_SPACING_MD,
                borderRadius: configEntzy.BORDER_SIZE_MD,
                backgroundColor: orange[200],
                color: "black",
              }}
            >
              <Typography
                variant="subtitle1"
                noWrap={true}
                sx={{
                  height: configEntzy.APP_SPACING_LG,
                  lineHeight: configEntzy.APP_SPACING_LG,
                  fontSize: configEntzy.FONT_SCALE_SM,
                  overflow: "visible",
                }}
              >
                <FontAwesomeIcon
                  icon={iconRunnerLaunched}
                  style={{ color: "orange" }}
                  fixedWidth
                />
                {eventView &&
                mainContext.state.viewer.trail.calendarCompactView ? (
                  <></>
                ) : (
                  <span>
                    <span>&nbsp;&nbsp;</span>
                    <span>LAUNCHED</span>
                    <span>&nbsp;</span>
                  </span>
                )}
              </Typography>
            </Box>
          ) : (
            <Box className="box-default">
              <Box
                className="box-default relative overflow pop-top"
                sx={{
                  width: props.triggers.ratio + "%",
                  height: 0,
                  fontSize: configEntzy.APP_SPACING_MD,
                  textAlign: "right",
                }}
                hidden={props.date.progress.trigger.total < 25}
              >
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon
                    icon={iconLaunched}
                    style={{ color: "orange" }}
                    transform="grow-12 left-4"
                  />
                  <FontAwesomeIcon
                    icon={iconLaunchedBorder}
                    style={{ color: "black" }}
                    transform="grow-12 left-4"
                  />
                </span>
              </Box>
              <Box className="box-default" sx={{ display: "inline-block" }}>
                <LinearProgress
                  variant="determinate"
                  value={props.date.progress.capacity.total}
                  color="active"
                  sx={{
                    height: configEntzy.APP_SPACING_LG,
                    borderRadius: configEntzy.BORDER_SIZE_MD,
                  }}
                />
              </Box>
              {eventView &&
              mainContext.state.viewer.trail.calendarCompactView ? (
                <></>
              ) : (
                <Box className="box-default">
                  <Box
                    className="box-inline right bg-black-t50"
                    sx={{
                      mt: configEntzy.APP_SPACING_SM,
                      pl: configEntzy.APP_SPACING_SM,
                      pr: configEntzy.APP_SPACING_SM,
                      borderRadius: configEntzy.BORDER_SIZE_XL,
                    }}
                  >
                    <Typography
                      variant="body2"
                      noWrap={true}
                      sx={{
                        fontSize: configEntzy.FONT_SCALE_SM,
                        color: "primary.light",
                      }}
                    >
                      {props.date.progress.remaining.capacity > 0 ? (
                        <em>
                          &nbsp;
                          {props.date.progress.remaining.nearlyFull
                            ? "ONLY "
                            : ""}
                          {props.date.progress.remaining.capacity} place
                          {props.date.progress.remaining.capacity !== 1 &&
                            "s"}{" "}
                          left &nbsp;
                        </em>
                      ) : (
                        <em>&nbsp;Sold Out&nbsp;</em>
                      )}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Box className="box-default">
          {props.runners ? (
            <Box className="box-default text-left">
              {props.date.isExpired ? (
                <Box
                  className="box-default"
                  sx={{
                    height: configEntzy.APP_SPACING_LG,
                    pl: configEntzy.APP_SPACING_MD,
                    border: "1px dashed #999",
                    borderRadius: configEntzy.BORDER_SIZE_MD,
                    backgroundColor: "divider",
                    color: "gray",
                  }}
                >
                  <Typography
                    variant="body2"
                    noWrap={true}
                    sx={{
                      height: configEntzy.APP_SPACING_LG,
                      lineHeight: configEntzy.APP_SPACING_LG,
                      fontSize: configEntzy.FONT_SCALE_SM,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={iconExpired}
                      style={{ color: "gray" }}
                      fixedWidth
                    />
                    <span>&nbsp;&nbsp;</span>
                    <span>EXPIRED</span>
                    <span>&nbsp;</span>
                  </Typography>
                </Box>
              ) : props.date.isRunnerOffer ? (
                <Box
                  className="box-default"
                  sx={{
                    height: configEntzy.APP_SPACING_LG,
                    pl: configEntzy.APP_SPACING_MD,
                    borderRadius: configEntzy.BORDER_SIZE_MD,
                    backgroundColor: "secondary.main",
                    color: "black",
                  }}
                >
                  <Typography
                    variant="body1"
                    noWrap={true}
                    sx={{
                      height: configEntzy.APP_SPACING_LG,
                      lineHeight: configEntzy.APP_SPACING_LG,
                      fontSize: configEntzy.FONT_SCALE_SM,
                      fontWeight: "bold",
                      overflow: "visible",
                    }}
                  >
                    <FontAwesomeIcon icon={iconRunnerOffer} fixedWidth />
                    {eventView &&
                    mainContext.state.viewer.trail.calendarCompactView ? (
                      <></>
                    ) : (
                      <span>
                        <span>&nbsp;&nbsp;</span>
                        <span>Run Date Offered</span>
                        <span>&nbsp;</span>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => {
                          return (
                            <FontAwesomeIcon
                              key={i}
                              icon={iconSignRight}
                              transform={"grow-10 right-" + 18 * i}
                              style={{ opacity: 0.2 }}
                              color="darkorange"
                            />
                          );
                        })}
                        <span>&nbsp;</span>
                      </span>
                    )}
                  </Typography>
                </Box>
              ) : props.date.isRunnerLaunchable ? (
                <Box
                  className="box-default"
                  sx={{
                    height: configEntzy.APP_SPACING_LG,
                    pl: configEntzy.APP_SPACING_MD,
                    border: "1px dashed #fff",
                    borderRadius: configEntzy.BORDER_SIZE_MD,
                    backgroundColor: orange[50],
                    color: "orange",
                  }}
                >
                  <Typography
                    variant="body2"
                    noWrap={true}
                    sx={{
                      height: configEntzy.APP_SPACING_LG,
                      lineHeight: configEntzy.APP_SPACING_LG,
                      fontSize: configEntzy.FONT_SCALE_SM,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={iconRunnerLaunchable}
                      style={{ color: "orange" }}
                      fixedWidth
                    />
                    {eventView &&
                    mainContext.state.viewer.trail.calendarCompactView ? (
                      <></>
                    ) : (
                      <span>
                        <span>&nbsp;&nbsp;</span>
                        <span>LAUNCHABLE</span>
                        <span>&nbsp;</span>
                      </span>
                    )}
                  </Typography>
                </Box>
              ) : (
                <Box
                  className="box-default"
                  sx={{
                    height: configEntzy.APP_SPACING_LG,
                    color: "#aaa",
                  }}
                >
                  <Typography
                    variant="body2"
                    noWrap={true}
                    sx={{
                      height: configEntzy.APP_SPACING_MD,
                      lineHeight: configEntzy.APP_SPACING_MD,
                      fontSize: configEntzy.FONT_SCALE_SM,
                    }}
                  >
                    <span>
                      <FontAwesomeIcon icon={iconRunnerNoOffer} fixedWidth />
                      <span>&nbsp;</span>
                    </span>
                    {eventView &&
                    mainContext.state.viewer.trail.calendarCompactView ? (
                      <></>
                    ) : (
                      <em>No run date offer yet</em>
                    )}
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Box className="box-default">
              <Box
                className="box-default"
                sx={{
                  width: props.triggers.ratio + "%",
                  display: "inline-block",
                }}
              >
                <Box className="box-default">
                  <Box
                    className="box-default relative overflow pop-top"
                    sx={{
                      width: props.date.progress.trigger.total + "%",
                      height: 0,
                      fontSize: configEntzy.APP_SPACING_MD,
                      textAlign: "right",
                    }}
                    hidden={props.date.progress.trigger.total < 25}
                  >
                    <span className="fa-layers fa-fw">
                      <FontAwesomeIcon
                        icon={iconTicking}
                        style={{
                          color: props.date.isExpired ? "gray" : "orange",
                        }}
                        transform="grow-10 right-20 rotate-45"
                      />
                      <FontAwesomeIcon
                        icon={iconTickingBorder}
                        style={{ color: "black" }}
                        transform="grow-10 right-20 rotate-45"
                      />
                    </span>
                  </Box>
                </Box>
                <Box className="box-default" sx={{ display: "inline-block" }}>
                  <LinearProgress
                    variant="determinate"
                    value={props.date.progress.trigger.total}
                    color={
                      props.date.isExpired
                        ? "primaryDisabled"
                        : props.date.progress.trigger.total > 50
                        ? "highlight"
                        : "primary"
                    }
                    sx={{
                      height: configEntzy.APP_SPACING_LG,
                      borderTopLeftRadius: configEntzy.BORDER_SIZE_MD,
                      borderBottomLeftRadius: configEntzy.BORDER_SIZE_MD,
                    }}
                  />
                </Box>
              </Box>
              <Box
                className="box-default"
                sx={{
                  width: 100 - props.triggers.ratio + "%",
                  display: "inline-block",
                  opacity: props.date.isExpired ? 0.5 : 1,
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={0}
                  color={props.date.isExpired ? "dusk" : "secondary"}
                  sx={{
                    height: configEntzy.APP_SPACING_LG,
                    borderTopRightRadius: configEntzy.BORDER_SIZE_MD,
                    borderBottomRightRadius: configEntzy.BORDER_SIZE_MD,
                  }}
                />
              </Box>

              {eventView &&
              mainContext.state.viewer.trail.calendarCompactView ? (
                <></>
              ) : (
                <Box className="box-default">
                  <Box
                    className="box-inline right bg-black-t50"
                    sx={{
                      mt: configEntzy.APP_SPACING_SM,
                      borderRadius: configEntzy.BORDER_SIZE_XL,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      noWrap={true}
                      sx={{
                        fontSize: configEntzy.FONT_SCALE_SM,
                        color: props.date.isExpired ? "#999" : "primary.light",
                      }}
                    >
                      <span>&nbsp;&nbsp;</span>
                      {props.date.isExpired ? (
                        <em>Notice period has past</em>
                      ) : props.date.isRunnerOffer ? (
                        <em>
                          {props.date.progress.count > 0 &&
                          props.launchPerTicket ? (
                            <span>
                              A ticket needs to hit {props.triggers.default}
                            </span>
                          ) : props.date.progress.count > 0 ? (
                            <span>
                              {props.date.progress.remaining.trigger} more to
                              launch
                            </span>
                          ) : (
                            <span>
                              {props.triggers.default} offer
                              {props.triggers.default === 1 ? "" : "s"} to
                              launch
                            </span>
                          )}
                        </em>
                      ) : props.date.isRunnerLaunchable ? (
                        <em>Enough for instant launch</em>
                      ) : (
                        <em>Needs runner buy in</em>
                      )}
                      <span>&nbsp;&nbsp;</span>
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export const DateTickingBreakdown = (props) => {
  let date, breakdown, ticketTypes;
  date = props.date;
  try {
    breakdown = JSON.parse(date.ticking.Breakdown);
    ticketTypes = JSON.parse(date.ticking.Meta).TicketTypes;
  } catch (error) {
    breakdown = {};
    ticketTypes = [];
  }
  return (
    <Box
      className="box-default"
      sx={{
        visibility: props.runners ? "hidden" : "visible",
        mt: ticketTypes.length ? configEntzy.APP_SPACING_MD : 0,
        pl: configEntzy.APP_SPACING_SM,
      }}
    >
      {ticketTypes.map((ticketType) => {
        return (
          <Box
            key={ticketType.id}
            className="box-default"
            sx={{
              p: configEntzy.APP_SPACING_XS,
            }}
            hidden={breakdown[ticketType.id].Quantity === 0}
          >
            <Box
              className={
                "box-default rounded " +
                (props.launched
                  ? "bg-highlight shadow-default"
                  : !props.launched &&
                    props.launchPerTicket &&
                    breakdown[ticketType.id] &&
                    breakdown[ticketType.id].Quantity >= props.triggers.default
                  ? "bg-highlight"
                  : "bg-black-t10")
              }
              sx={{
                pl: configEntzy.APP_SPACING_SM,
                pr: configEntzy.APP_SPACING_SM,
                pt: configEntzy.APP_SPACING_XS3X,
                pb: configEntzy.APP_SPACING_XS2X,
                borderRadius: configEntzy.BORDER_SIZE_XL,
                color: props.launched
                  ? "black"
                  : !props.launched &&
                    props.launchPerTicket &&
                    breakdown[ticketType.id] &&
                    breakdown[ticketType.id].Quantity >= props.triggers.default
                  ? "black"
                  : "#999",
              }}
            >
              <Box className="box-default q3-width upper-case">
                <Typography variant="subtitle2" noWrap={true}>
                  <span>&nbsp;</span>
                  <FontAwesomeIcon icon={iconTickets} />
                  <span>&nbsp;&nbsp;</span>
                  {ticketType.name}
                  <span>&nbsp;</span>
                </Typography>
              </Box>
              <Box className="box-default quarter-width text-right">
                <Typography variant="subtitle1" noWrap={true}>
                  <span>
                    {breakdown[ticketType.id]
                      ? breakdown[ticketType.id].Quantity
                      : 0}
                    {!props.launched &&
                      props.launchPerTicket &&
                      " /" + props.triggers.default}
                  </span>
                  <span>&nbsp;</span>
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export const DateLaunched = (props) => {
  return (
    <Box
      className="box-default action-pointer"
      onClick={() => props.focusModule("dates", "rider-offer")}
    >
      <Box
        className="box-default shadow-light"
        sx={{
          mb: configEntzy.APP_SPACING_XS,
          p: configEntzy.APP_SPACING_MD,
          backgroundColor: orange[50],
        }}
      >
        <Box className="box-default">
          <Typography variant="subtitle2" noWrap={true}>
            {/* <FontAwesomeIcon
              icon={iconLaunched}
              color={orange[500]}
              fixedWidth
            />
            <span>&nbsp;&nbsp;&nbsp;</span> */}
            {dayjs(props.dateid).format("DD MMM YYYY")}
            <span className="content-disabled">&nbsp;|&nbsp;</span>
            {dayjs(props.dateid).format("ddd")}
          </Typography>
        </Box>
        <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_SM }}>
          <Typography variant="subtitle1" noWrap={true}>
            <FontAwesomeIcon icon={iconCapacity} fixedWidth />
            <span>&nbsp;&nbsp;&nbsp;</span>
            {props.history ? (
              <span>{props.data.Quantity} attendees</span>
            ) : (
              <span>
                {props.date.progress.remaining.capacity > 0 ? (
                  <em>
                    &nbsp;
                    {props.date.progress.remaining.nearlyFull ? "ONLY " : ""}
                    {props.date.progress.remaining.capacity} place
                    {props.date.progress.remaining.capacity !== 1 && "s"} left
                    &nbsp;
                  </em>
                ) : (
                  <em>&nbsp;Sold Out&nbsp;</em>
                )}
                {/* {props.date.progress.remaining.capacity > 0 ? (
                  <span>
                    <span>
                      {props.date.progress.remaining.capacity + " places left"}
                    </span>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <span className="shadow-light" style={{ padding: "2px" }}>
                      &nbsp;GET&nbsp;
                    </span>
                  </span>
                ) : (
                  "Sold out"
                )} */}
              </span>
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
