// react core
import { useContext } from "react";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenLine as iconTagline } from "@fortawesome/pro-duotone-svg-icons";
import { faInfoCircle as iconHappening } from "@fortawesome/pro-duotone-svg-icons";
import { faClose as iconClose } from "@fortawesome/pro-thin-svg-icons";
import { faTimes as iconInactive } from "@fortawesome/pro-duotone-svg-icons";
import { faEdit as iconEdit } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle as iconCircle } from "@fortawesome/pro-solid-svg-icons";

// entzy resources
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";
// import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";

// entzy components
import WidgetRichText from "components/event/widgets/WidgetRichText";

function ActionLaunchpadRunnerDetails(props) {
  const section = "details";
  // const user = props.user;
  const editing = props.editing;
  const handleEdit = props.handleEdit;
  const anchorTop = "anchor-launchpad-edit-" + section + "-top";
  const handleRunnerActionCallback = props.handleRunnerActionCallback;

  // const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);

  return (
    <Box id={anchorTop} className="box-default">
      <Box className="box-default">
        <Typography variant="h6" color="primary">
          Launch Details
        </Typography>
        <Typography variant="subtitle1" color="white">
          <em>Set what happens on launch</em>
        </Typography>
      </Box>
      {editing ? (
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_LG,
          }}
        >
          <InputForm
            navigate={props.navigate}
            fields={[
              {
                id: "event-tagline",
                type: "text-standard",
                label: "Eventuator Tagline",
                value: eventContext.state.event.data.Tagline
                  ? eventContext.state.event.data.Tagline
                  : "",
                maxLength: eventContext.state.constants.events.maxTaglineLength,
              },
              {
                id: "event-details",
                type: "text-rich",
                label: "What happens on Launch",
                value: eventContext.state.event.data.Details
                  ? eventContext.state.event.data.Details
                  : "",
                maxLength: eventContext.state.constants.events.maxDetailsLength,
                required: true,
              },
            ]}
            // warnOnChange={true} // (no longer needed as warnings are now in edit changes)
            submitText="Update Launch Details"
            callback={handleRunnerActionCallback}
            cancel={() => {
              handleEdit();
            }}
            cancelText={
              <span>
                <span>Exit Details Editor</span>
                <span>&nbsp;&nbsp;</span>
                <FontAwesomeIcon
                  icon={iconClose}
                  transform="grow-6 right-3"
                  fixedWidth
                />
              </span>
            }
            cancelTitle="Double checking..."
            cancelDescription="Are you sure you want to exit main text editor for the launch details? Any unsaved changes will be lost."
          />
        </Box>
      ) : (
        <Box
          className="box-default text-left action-pointer"
          sx={{
            mt: configEntzy.APP_SPACING_LG,
          }}
          onClick={() => handleEdit("details")}
        >
          <Box
            className="box-default shadow-light"
            sx={{
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <Box
              className="box-default"
              sx={{
                pb: configEntzy.APP_SPACING_MD,
                borderBottom: "1px solid #ccc",
              }}
            >
              <Typography variant="subtitle2">
                <FontAwesomeIcon icon={iconTagline} />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span>Eventuator Tagline</span>
              </Typography>
            </Box>
            <Box className="box-default">
              <Typography
                variant="subtitle2"
                sx={{
                  mt: configEntzy.APP_SPACING_MD,
                }}
              >
                <em>
                  {eventContext.state.event.data.Tagline ? (
                    eventContext.state.event.data.Tagline
                  ) : (
                    <span>
                      <span>Tagline Inactive&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <FontAwesomeIcon icon={iconInactive} color="red" />
                    </span>
                  )}
                </em>
              </Typography>
            </Box>
          </Box>
          {/* EDIT ICON IN MIDDLE */}
          {/* ----------------------------------------- */}
          <Box className="box-default zero-height text-right">
            <Typography variant="h1" color="primary">
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon
                  icon={iconCircle}
                  color="#ccc"
                  transform="grow-15 up-8"
                />
                <FontAwesomeIcon
                  icon={iconCircle}
                  color="white"
                  transform="grow-14 up-8"
                />
                <FontAwesomeIcon icon={iconEdit} transform="up-8" />
              </span>
            </Typography>
          </Box>
          {/* ----------------------------------------- */}
          <Box
            className="box-default shadow-light"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <Box
              className="box-default"
              sx={{
                pb: configEntzy.APP_SPACING_MD,
                borderBottom: "1px solid #ccc",
              }}
            >
              <Typography variant="subtitle2">
                <FontAwesomeIcon icon={iconHappening} />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span>What happens on Launch</span>
              </Typography>
            </Box>
            {eventContext.state.event.data.Details ? (
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD,
                }}
              >
                <WidgetRichText
                  content={eventContext.state.event.data.Details}
                />
              </Box>
            ) : (
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_MD,
                }}
              >
                <Typography variant="subtitle2">
                  <span>No details set</span>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ActionLaunchpadRunnerDetails;
