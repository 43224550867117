// graphql mutations
export const oauthCodeExchange = `mutation OauthCodeExchange(
	$IdentityProvider: String!
	$SourceUrl: String!
	$Code: String!
) {
  oauthCodeExchange(
		IdentityProvider: $IdentityProvider
		SourceUrl: $SourceUrl
		Code: $Code
	)
}
`;
export const oauthTokenRefresh = `mutation OauthTokenRefresh(
	$IdentityProvider: String!
	$SourceUrl: String!
	$RefreshAction: String
) {
  oauthTokenRefresh(
		IdentityProvider: $IdentityProvider
		SourceUrl: $SourceUrl
		RefreshAction: $RefreshAction
	)
}
`;

export const syncUser = `mutation SyncUser(
	$Action: String!
) {
  syncUser(
		Action: $Action
	) {
		Email
		Name
		PaymentId
		PayoutId
		IdentityProvider
  }
}
`;
export const pushSubscribe = `mutation PushSubscribe(
	$SubscribeUserId: String!
	$SubscribePlatform: String!
	$SubscribeToken: String!
) {
  pushSubscribe(
		SubscribeUserId: $SubscribeUserId
		SubscribePlatform: $SubscribePlatform
		SubscribeToken: $SubscribeToken
	)
}
`;
export const pushUpdateNotification = `mutation PushUpdateNotification(
	$NotificationId: String!
	$NotificationAction: String!
) {
  pushUpdateNotification(
		NotificationId: $NotificationId
		NotificationAction: $NotificationAction
	)
}
`;
export const updatePayment = `mutation UpdatePayment(
	$PaymentToken: String!
) {
  updatePayment(
		PaymentToken: $PaymentToken
	) {
		PaymentId
  }
}
`;
export const updatePayout = `mutation UpdatePayout(
	$PayoutCode: String!
) {
  updatePayout(
		PayoutCode: $PayoutCode
	) {
		PayoutId
  }
}
`;

export const createCalendarTimelineEntry = `mutation CreateCalendarTimelineEntry(
	$DateKey: String!
	$Description: String!
) {
  createCalendarTimelineEntry(
		DateKey: $DateKey
		Description: $Description
	) {
		UserId
		DateId
		DateKey
		Category
		Description
		DateMeta
		EventId
		EventUserId
		EventUrl
		EventCountry
		EventCurrency
		TicketId
		TicketUserId
		TicketDescription
		TicketCurrency
		TicketQuantity
		TicketUnitAmount
		TicketTotalAmount
		TicketEntryDate
		LastUpdated
		FirstCreated
	}
}
`;
export const updateCalendarTimelineEntry = `mutation UpdateCalendarTimelineEntry(
	$DateId: String!
	$Description: String!
	$DateMeta: String
) {
  updateCalendarTimelineEntry(
		DateId: $DateId
		Description: $Description
		DateMeta: $DateMeta
	) {
		UserId
		DateId
		DateKey
		Category
		Description
		DateMeta
		EventId
		EventUserId
		EventUrl
		EventCountry
		EventCurrency
		TicketId
		TicketUserId
		TicketDescription
		TicketCurrency
		TicketQuantity
		TicketUnitAmount
		TicketTotalAmount
		TicketEntryDate
		LastUpdated
		FirstCreated
	}
}
`;
export const deleteCalendarTimelineEntry = `mutation DeleteCalendarTimelineEntry(
	$DateId: String!
) {
  deleteCalendarTimelineEntry(
		DateId: $DateId
	) {
		UserId
		DateId
		DateKey
		Category
		Description
		DateMeta
		EventId
		EventUserId
		EventUrl
		EventCountry
		EventCurrency
		TicketId
		TicketUserId
		TicketDescription
		TicketCurrency
		TicketQuantity
		TicketUnitAmount
		TicketTotalAmount
		TicketEntryDate
		LastUpdated
		FirstCreated
	}
}
`;

export const createEvent = `mutation CreateEvent(
	$Name: String!
	$Country: String!
	$Currency: String!
) {
  createEvent(
		Name: $Name
		Country: $Country
		Currency: $Currency
	) {
		EventId
		UserId
		Url
		Active
		Access
		Country
		Currency
		Details
		WelcomeDetails
		LaunchPerTicket
		Logo
		Privacy
		Tagline
		Dates{
			id
			tag
			subtag
			order
			active
			base
			add
		}
		Triggers{
			id
			tag
			subtag
			order
			active
			unit
			min
		}
		Limits{
			id
			tag
			subtag
			order
			active
			name
			unit
			max
		}
		TicketTypes{
			id
			tag
			subtag
			order
			active
			name
			price
			min
			max
		}
		Badges{
			id
			tag
			subtag
			order
			active
			name
			price
			max
			description
		}
		ImageFrames{
			id
			tag
			subtag
			order
			active
			path
		}
		Ticking{
			DateKey
			Currency
			Quantity
			Amount
			Breakdown
			Meta
			Ticket
			TickStatus
		}
		LastUpdated
		FirstCreated
  }
}
`;

export const createUserSetting = `mutation CreateUserSetting(
	$SettingId: String!
	$SettingValue: String!
) {
  createUserSetting(
		SettingId: $SettingId
		SettingValue: $SettingValue
	) {
		SettingId
		UserId
		SettingValue
		LastUpdated
		FirstCreated
	}
}
`;
export const deleteUserSetting = `mutation DeleteUserSetting(
	$SettingId: String!
) {
  deleteUserSetting(
		SettingId: $SettingId
	) {
		SettingId
		UserId
		SettingValue
		LastUpdated
		FirstCreated
	}
}
`;

export const createInterest = `mutation CreateInterest(
	$Category: String!
	$SubCategory: String
	$Url: String
	$EventId: String
	$TicketId: String
	$RoomId: String
	$ActivityId: String
) {
  createInterest(
		Category: $Category
		SubCategory: $SubCategory
		Url: $Url
		EventId: $EventId
		TicketId: $TicketId
		RoomId: $RoomId
		ActivityId: $ActivityId
	) {
		InterestId
		LastUpdated
		FirstCreated
	}
}
`;
export const deleteInterest = `mutation DeleteInterest(
	$Category: String!
	$SubCategory: String
	$Url: String
	$EventId: String
	$TicketId: String
	$RoomId: String
	$ActivityId: String
) {
  deleteInterest(
		Category: $Category
		SubCategory: $SubCategory
		Url: $Url
		EventId: $EventId
		TicketId: $TicketId
		RoomId: $RoomId
		ActivityId: $ActivityId
	) {
		InterestId
		LastUpdated
		FirstCreated
	}
}
`;

export const joinEventEntry = `mutation JoinEventEntry(
	$EventId: String!
	$AcceptUserId: String
) {
  joinEventEntry(
		EventId: $EventId
		AcceptUserId: $AcceptUserId
	) 
}
`;
export const joinEventExit = `mutation JoinEventExit(
	$EventId: String!
	$RemoveUserId: String
	$RemoveManagerAccessOnly: Boolean
) {
  joinEventExit(
		EventId: $EventId
		RemoveUserId: $RemoveUserId
		RemoveManagerAccessOnly: $RemoveManagerAccessOnly
	) 
}
`;
export const generateJoinLink = `mutation GenerateJoinLink(
	$EventId: String!
	$Category: String!
) {
  generateJoinLink(
		EventId: $EventId
		Category: $Category
	)
}
`;
export const acceptJoinLink = `mutation AcceptJoinLink(
	$EventId: String!
	$InviteCode: String!
) {
  acceptJoinLink(
		EventId: $EventId
		InviteCode: $InviteCode
	)
}
`;

export const postLink = `mutation PostLink(
	$EventId: String!
	$LinkType: String!
	$LinkTarget: String!
	$Title: String
	$Description: String
) {
  postLink(
		EventId: $EventId
		LinkType: $LinkType
		LinkTarget: $LinkTarget
		Title: $Title
		Description: $Description
	) {
		EventId
		LinkId
		LinkType
		LinkTarget
		UserId
		Title
		Description
		Category
		FirstCreated
		LastUpdated
	}
}
`;
export const deleteLink = `mutation DeleteLink(
	$EventId: String!
	$LinkId: String!
) {
  deleteLink(
		EventId: $EventId
		LinkId: $LinkId
	) {
		EventId
		LinkId
		LinkType
		LinkTarget
		UserId
		Title
		Description
		Category
		FirstCreated
		LastUpdated
	}
}
`;

export const postChatAiContent = `mutation PostChatAiContent(
	$EventId: String!
	$RoomName: String!
	$ContentType: String!
	$ContentData: String!
	$ContentImage: String
) {
  postChatAiContent(
		EventId: $EventId
		RoomName: $RoomName
		ContentType: $ContentType
		ContentData: $ContentData
		ContentImage: $ContentImage
	) {
		human {
			RoomId
			PostId
			UserId
			EventId
			RoomArea
			RoomName
			Category
			ContentType
			ContentData
			ContentImage
			PostDeleted
			FirstCreated
			LastUpdated
		}
		assistant {
			RoomId
			PostId
			UserId
			EventId
			RoomArea
			RoomName
			Category
			ContentType
			ContentData
			PostDeleted
			FirstCreated
			LastUpdated
		}
	}
}
`;
export const postChatEventContent = `mutation PostChatEventContent(
	$EventId: String!
	$RoomName: String!
	$ContentType: String!
	$ContentData: String!
	$ContentImage: String
	$InReplyTo: String
) {
  postChatEventContent(
		EventId: $EventId
		RoomName: $RoomName
		ContentType: $ContentType
		ContentData: $ContentData
		ContentImage: $ContentImage
		InReplyTo: $InReplyTo
	) {
		RoomId
		PostId
		UserId
		EventId
		RoomArea
		RoomName
		Category
		ContentType
		ContentData
		ContentImage
		InReplyTo
		PostDeleted
		FirstCreated
		LastUpdated
	}
}
`;
export const postChatDirectContent = `mutation PostChatDirectContent(
	$RoomArea: String!
	$RoomName: String!
	$ContentType: String!
	$ContentData: String!
	$ContentImage: String
	$InReplyTo: String
) {
  postChatDirectContent(
		RoomArea: $RoomArea
		RoomName: $RoomName
		ContentType: $ContentType
		ContentData: $ContentData
		ContentImage: $ContentImage
		InReplyTo: $InReplyTo
	) {
		RoomId
		PostId
		UserId
		EventId
		RoomArea
		RoomName
		Category
		ContentType
		ContentData
		ContentImage
		InReplyTo
		PostDeleted
		FirstCreated
		LastUpdated
	}
}
`;
export const removeChatEventContent = `mutation RemoveChatEventContent(
	$EventId: String!
	$RoomName: String!
	$PostId: String!
) {
  removeChatEventContent(
		EventId: $EventId
		RoomName: $RoomName
		PostId: $PostId
	) {
		RoomId
		PostId
		UserId
		EventId
		RoomArea
		RoomName
		Category
		ContentType
		ContentData
		ContentImage
		InReplyTo
		PostDeleted
		FirstCreated
		LastUpdated
	}
}
`;
export const removeChatDirectContent = `mutation RemoveChatDirectContent(
	$RoomArea: String!
	$RoomName: String!
	$PostId: String!
) {
  removeChatDirectContent(
		RoomArea: $RoomArea
		RoomName: $RoomName
		PostId: $PostId
	) {
		RoomId
		PostId
		UserId
		EventId
		RoomArea
		RoomName
		Category
		ContentType
		ContentData
		ContentImage
		InReplyTo
		PostDeleted
		FirstCreated
		LastUpdated
	}
}
`;

export const postChat = `mutation PostChat(
	$EventId: String!
	$BundleKey: String!
	$BundleAction: String!
) {
  postChat(
		EventId: $EventId
		BundleKey: $BundleKey
		BundleAction: $BundleAction
	) {
		ChatId
		UserId
		UserName
		UserStatus
		EventId
		Url
		Message
		Category
		SourceId
		Aged
		Files{
			FileId
			FileName
			FileType
		}
		Places{
			PlaceId
			PlaceName
			PlaceCoordinates
		}
		Mentions{
			MentionId
			MentionName
			MentionUserId
		}
		FirstCreated
		LastUpdated
	}
}
`;
export const updateChatAlerts = `mutation UpdateChatAlerts(
	$EventId: String!
	$Settings: String!
) {
	updateChatAlerts(
		EventId: $EventId
		Settings: $Settings
	) {
		SettingId
		UserId
		UserName
		UserEmail
		EventId
		Url
		Settings
		LastUpdated
		FirstCreated
	}
}
`;

export const updateEvent = `mutation UpdateEvent(
	$EventId: String!
	$Active: Boolean
	$Access: String
	$Details: String
	$WelcomeDetails: String
	$LaunchPerTicket: Boolean
	$Logo: String
	$Privacy: String
	$Tagline: String
	$Dates: [SettingInput!]
	$Triggers: [SettingInput!]
	$Limits: [SettingInput!]
	$TicketTypes: [SettingInput!]
	$Badges: [SettingInput!]
	$ImageFrames: [SettingInput!]
	$Changed: [String]
) {
  updateEvent(
		EventId: $EventId
		Active: $Active
		Access: $Access
		Details: $Details
		WelcomeDetails: $WelcomeDetails
		LaunchPerTicket: $LaunchPerTicket
		Logo: $Logo
		Privacy: $Privacy
		Tagline: $Tagline
		Dates: $Dates
		Triggers: $Triggers
		Limits: $Limits
		TicketTypes: $TicketTypes
		Badges: $Badges
		ImageFrames: $ImageFrames
		Changed: $Changed
	) {
		Url
		Active
		Access
		Currency
		Details
		WelcomeDetails
		LaunchPerTicket
		Logo
		Privacy
		Tagline
		Dates{
			id
			tag
			subtag
			order
			active
			base
			add
		}
		Triggers{
			id
			tag
			subtag
			order
			active
			unit
			min
		}
		Limits{
			id
			tag
			subtag
			order
			active
			name
			unit
			max
		}
		TicketTypes{
			id
			tag
			subtag
			order
			active
			name
			price
			min
			max
		}
		Badges{
			id
			tag
			subtag
			order
			active
			name
			price
			max
			description
		}
		ImageFrames{
			id
			tag
			subtag
			order
			active
			path
		}
		Ticking{
			DateKey
			Currency
			Quantity
			Amount
			Breakdown
			Meta
			Ticket
			TickStatus
			LastUpdated
			FirstCreated
		}
	}
}
`;
export const requestJoinEvent = `mutation RequestJoinEvent(
	$EventId: String!
	$Action: String!
) {
  requestJoinEvent(
		EventId: $EventId
		Action: $Action
	) {
		UserId
		Name
		Email
		Status
		LastUpdated
		FirstCreated
	}
}
`;

export const updatePlaces = `mutation UpdatePlaces(
	$EventId: String!
	$Locations: [SettingInput!]!
) {
  updatePlaces(
		EventId: $EventId
		Locations: $Locations
	) {
		EventId
		LinkId
		LinkType
		LinkTarget
		LinkStatus
		UserId
		Title
		Description
		Category
		LastUpdated
		FirstCreated
	}
}
`;

export const purchaseTicket = `mutation PurchaseTicket(
	$EventId: String!
	$TypeId: String!
	$Quantity: Int!
) {
  purchaseTicket(
		EventId: $EventId
		TypeId: $TypeId
		Quantity: $Quantity
	) {
		UserId
		Url
		TicketId
		Description
		Currency
		Quantity
		UnitAmount
		TotalAmount
		TypeMeta
		LastUpdated
		FirstCreated
	}
}
`;
export const rechargeTicket = `mutation RechargeTicket(
	$EventId: String!
	$TicketId: String!
) {
  rechargeTicket(
		EventId: $EventId
		TicketId: $TicketId
	)
}
`;
export const withdrawTicket = `mutation WithdrawTicket(
	$EventId: String!
	$TicketId: String!
) {
  withdrawTicket(
		EventId: $EventId
		TicketId: $TicketId
	)
}
`;
export const validateTicket = `mutation ValidateTicket(
	$DateId: String!
	$EventId: String!
	$TicketId: String!
	$TicketUserId: String!
	$TicketHash: String!
) {
  validateTicket(
		DateId: $DateId
		EventId: $EventId
		TicketId: $TicketId
		TicketUserId: $TicketUserId
		TicketHash: $TicketHash
	) {
		EventId
		TicketId
		TypeId
		UserId
		Url
		Quantity
		Description
		Currency
		UnitAmount
		TotalAmount
		PartKey
		EntryDate
		EntryStatus
		Shareholders{
			UserEmail
			UserName
			UserOwner
			UserViewer
			Category
			Quantity
			BillingQuantity
			PendingQuantity
			Status
			LastUpdated
			FirstCreated
		}
		TickedStatus
		TickedDates{
			id
			order
			active
			tag
			subtag
			base
			add
		}
	}
}
`;
export const validateBadge = `mutation ValidateBadge(
	$EventId: String!
	$BadgeId: String!
	$BadgeUserId: String!
	$BadgeHash: String!
) {
  validateBadge(
		EventId: $EventId
		BadgeId: $BadgeId
		BadgeUserId: $BadgeUserId
		BadgeHash: $BadgeHash
	) {
		EventId
		UserBadgeId
		BadgeId
		UserId
		UserName
		UserEmail
		BadgeName
		BadgeDescription
		BadgeStatus
		BadgeExpires
		Url
		Currency
		Amount
		PartKey
		Charge
		EntryStatus
		LastUpdated
		FirstCreated
	}
}
`;

export const entryHandshake = `mutation EntryHandshake(
	$DateId: String!
	$EventId: String!
	$TicketId: String!
	$TicketUserId: String!
	$TicketHash: String!
	$TicketAction: String!
) {
  entryHandshake(
		DateId: $DateId
		EventId: $EventId
		TicketId: $TicketId
		TicketUserId: $TicketUserId
		TicketHash: $TicketHash
		TicketAction: $TicketAction
	) {
		EventId
		TicketId
		TicketUserId
		TicketAction
		TicketStatus
		TicketQuantity
		Shareholders{
			TicketId
			UserId
			UserEmail
			UserName
			UserOwner
			UserViewer
			Category
			Quantity
			BillingQuantity
			PendingQuantity
			Status
			LastUpdated
			FirstCreated
		}
		EntryUserId
		EntryDate
		EntryStatus
	}
}
`;

export const collectHolding = `mutation CollectHolding(
	$EventId: String!
	$TicketId: String!
	$Status: String!
) {
	collectHolding(
		EventId: $EventId
		TicketId: $TicketId
		Status: $Status
	) {
		UserEmail
		UserName
		UserViewer
		UserOwner
		Category
		Quantity
		BillingQuantity
		PendingQuantity
		Status
		LastUpdated
		FirstCreated
  }
}
`;
export const manageHolding = `mutation ManageHolding(
	$EventId: String!
	$TicketId: String!
	$ContactName: String!
	$ContactEmail: String
	$Category: String!
	$Quantity: Int!
	$Status: String!
) {
	manageHolding(
		EventId: $EventId
		TicketId: $TicketId
		ContactName: $ContactName
		ContactEmail: $ContactEmail
		Category: $Category
		Quantity: $Quantity
		Status: $Status
	) {
		UserEmail
		UserName
		UserViewer
		UserOwner
		Category
		Quantity
		BillingQuantity
		PendingQuantity
		Status
		LastUpdated
		FirstCreated
  }
}
`;

export const tickDates = `mutation TickDates(
	$EventId: String!
	$TicketId: String!
	$SettingBase: String!
	$SettingAdd: [Int!]!
) {
  tickDates(
		EventId: $EventId
		TicketId: $TicketId
		SettingBase: $SettingBase
		SettingAdd: $SettingAdd
	) {
		TicketId
		Url
		LastUpdated
		FirstCreated
	}
}
`;

export const purchaseBadge = `mutation PurchaseBadge(
	$EventId: String!
	$BadgeId: String!
	$Transaction: String!
) {
  purchaseBadge(
		EventId: $EventId
		BadgeId: $BadgeId
		Transaction: $Transaction
	)
}
`;

export const triggerReversal = `mutation TriggerReversal(
	$EventId: String!
	$DateId: String!
	$TicketId: String
	$HolderId: String
) {
  triggerReversal(
		EventId: $EventId
		DateId: $DateId
		TicketId: $TicketId
		HolderId: $HolderId
	)
}
`;

export const sendDirectPayment = `mutation SendDirectPayment(
	$RecipientType: String!
	$RecipientName: String!
	$Currency: String!
	$Amount: Int!
	$Description: String
) {
  sendDirectPayment(
		RecipientType: $RecipientType
		RecipientName: $RecipientName
		Currency: $Currency
		Amount: $Amount
		Description: $Description
	)
}
`;
export const reverseDirectPayment = `mutation ReverseDirectPayment(
	$TransactionId: String!
) {
  reverseDirectPayment(
		TransactionId: $TransactionId
	)
}
`;

export const createUserProduct = `mutation CreateUserProduct(
	$Name: String!
	$List: String!
	$Category: String!
	$Currency: String!
	$Amount: Int!
	$Description: String!
) {
  createUserProduct(
		Name: $Name
		List: $List
		Category: $Category
		Currency: $Currency
		Amount: $Amount
		Description: $Description
	) {
		SellerId
		ProductId
		List
		Category
		Currency
		Amount
		Description
		ProductStatus
		LastUpdated
		FirstCreated
	}
}
`;
export const updateUserProduct = `mutation UpdateUserProduct(
	$Name: String!
	$ProductId: String!
	$ProductStatus: String!
	$Currency: String!
	$Amount: Int!
	$Description: String!
) {
  updateUserProduct(
		Name: $Name
		ProductId: $ProductId
		ProductStatus: $ProductStatus
		Currency: $Currency
		Amount: $Amount
		Description: $Description
	) {
		SellerId
		ProductId
		List
		Category
		Currency
		Amount
		Description
		ProductStatus
		LastUpdated
		FirstCreated
	}
}
`;
export const deleteUserProduct = `mutation DeleteUserProduct(
	$Name: String!
	$ProductId: String!
) {
  deleteUserProduct(
		Name: $Name
		ProductId: $ProductId
	) {
		SellerId
		ProductId
		List
		Category
		Currency
		Amount
		Description
		ProductStatus
		LastUpdated
		FirstCreated
	}
}
`;

export const createEventProduct = `mutation CreateEventProduct(
	$Url: String!
	$List: String!
	$Category: String!
	$Currency: String!
	$Amount: Int!
	$Description: String!
) {
  createEventProduct(
		Url: $Url
		List: $List
		Category: $Category
		Currency: $Currency
		Amount: $Amount
		Description: $Description
	) {
		SellerId
		ProductId
		List
		Category
		Currency
		Amount
		Description
		ProductStatus
		LastUpdated
		FirstCreated
	}
}
`;
export const updateEventProduct = `mutation UpdateEventProduct(
	$Url: String!
	$ProductId: String!
	$ProductStatus: String!
	$Currency: String!
	$Amount: Int!
	$Description: String!
) {
  updateEventProduct(
		Url: $Url
		ProductId: $ProductId
		ProductStatus: $ProductStatus
		Currency: $Currency
		Amount: $Amount
		Description: $Description
	) {
		SellerId
		ProductId
		List
		Category
		Currency
		Amount
		Description
		ProductStatus
		LastUpdated
		FirstCreated
	}
}
`;
export const deleteEventProduct = `mutation DeleteEventProduct(
	$Url: String!
	$ProductId: String!
) {
  deleteEventProduct(
		Url: $Url
		ProductId: $ProductId
	) {
		SellerId
		ProductId
		List
		Category
		Currency
		Amount
		Description
		ProductStatus
		LastUpdated
		FirstCreated
	}
}
`;

export const createUserContact = `mutation CreateUserContact(
	$Name: String!
	$ContactUserId: String
) {
  createUserContact(
		Name: $Name
		ContactUserId: $ContactUserId
	) {
		UserId
		ContactUserId
		LastUpdated
		FirstCreated
	}
}
`;
export const deleteUserContact = `mutation DeleteUserContact(
	$ContactUserId: String!
) {
  deleteUserContact(
		ContactUserId: $ContactUserId
	) {
		UserId
		ContactUserId
		LastUpdated
		FirstCreated
	}
}
`;

export const sendMessage = `mutation SendMessage(
	$SendTo: String!
	$SendSubject: String!
	$SendBody: String!
	$EventId: String
) {
  sendMessage(
		SendTo: $SendTo
		SendSubject: $SendSubject
		SendBody: $SendBody
		EventId: $EventId
	)
}
`;

export const closeAccount = `mutation CloseAccount(
	$Comments: String
) {
	closeAccount(
		Comments: $Comments
	)
}
`;

export const logCapture = `mutation LogCapture(
	$LogLevel: String!
	$LogMessage: String!
	$LogTrace: String
) {
  logCapture(
		LogLevel: $LogLevel
		LogMessage: $LogMessage
		LogTrace: $LogTrace
	)
}
`;
