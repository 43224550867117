// react core
import { useContext, useState } from "react";

// linkify highlighting
import Linkify from "react-linkify";

// material design
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt as iconDelete } from "@fortawesome/pro-solid-svg-icons";
import { faClose as iconClose } from "@fortawesome/pro-solid-svg-icons";
import { faCircle as iconCircle } from "@fortawesome/pro-solid-svg-icons";
import { faHandPointer as iconPointer } from "@fortawesome/pro-solid-svg-icons";
import { faReply as iconReplyPost } from "@fortawesome/pro-thin-svg-icons";
import { faEllipsisV as iconActions } from "@fortawesome/pro-solid-svg-icons";
import { faBan as iconDisabled } from "@fortawesome/pro-solid-svg-icons";
// import { faMessageArrowUpRight as iconArrow } from "@fortawesome/pro-solid-svg-icons";
// import { faClock as iconAge } from "@fortawesome/pro-duotone-svg-icons";
// import { faCommentAlt as iconPost } from "@fortawesome/pro-duotone-svg-icons";
// import { faImage as iconImage } from "@fortawesome/pro-duotone-svg-icons";

// entzy config and components
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import ActionCore from "../actions/ActionCore";
import { UserAvatar } from "components/utils/common/CommonAvatars";
import { ActionLoader } from "components/utils/common/CommonLoaders";
import { ActionButton } from "components/utils/common/CommonButtons";
import { getAge } from "models/Tools";

// entzy hooks
import { useMediaImage } from "hooks/media/mediaImage";

const PostControls = (props) => {
  const showConfirmDelete =
    props.canRemove && props.removing === props.item.PostId;
  const showReply =
    props.item &&
    props.item.RoomName &&
    props.item.RoomName.startsWith("desk") &&
    props.item.Category === "runner"
      ? false
      : props.showReply;
  const [showActions, setShowActions] = useState(false);

  const handleShowActions = () => {
    setShowActions(!showActions);
  };

  return (
    <Box
      className="box-default"
      sx={{
        borderTop: "1px solid #ccc",
      }}
    >
      <Box
        className="box-default text-left proper-case action-pointer"
        sx={{
          p: configEntzy.APP_SPACING_SM2X,
        }}
        onClick={handleShowActions}
        hidden={showConfirmDelete}
      >
        <Typography variant="subtitle2" color="primary" noWrap>
          <span
            style={{
              float: showActions ? "right" : "left",
            }}
          >
            <FontAwesomeIcon
              icon={showActions ? iconClose : iconActions}
              fixedWidth
            />
          </span>
          {!showActions && (
            <span>
              <span>
                &nbsp;
                {props.memberMessaging
                  ? "DM"
                  : props.notification
                  ? "Edit"
                  : props.item.Category}
                &nbsp;
              </span>
              <span
                style={{
                  float: "right",
                }}
              >
                {getAge({ timestamp: props.item.LastUpdated })}
              </span>
            </span>
          )}
        </Typography>
      </Box>
      <Box
        className="box-default shadow-basic nooverflow"
        hidden={showConfirmDelete || !showActions}
        sx={{
          pt: configEntzy.APP_SPACING_SM2X,
          pb: configEntzy.APP_SPACING_SM2X,
          backgroundColor: "dusk.dark",
          borderBottomLeftRadius: configEntzy.BORDER_SIZE_LG,
          borderBottomRightRadius: configEntzy.BORDER_SIZE_LG,
        }}
      >
        <Box
          className={
            props.memberMessaging ? "box-default" : "box-default half-width"
          }
          sx={{
            pl: configEntzy.APP_SPACING_SM2X,
            pr: props.memberMessaging
              ? configEntzy.APP_SPACING_SM2X
              : configEntzy.APP_SPACING_XS2X,
          }}
        >
          <ActionButton
            variant="outlined"
            size="small"
            color="danger"
            fullWidth={true}
            disabled={props.canRemove ? false : true}
            disableElevation={true}
            text={
              <span>
                <FontAwesomeIcon
                  icon={props.canRemove ? iconDelete : iconDisabled}
                  transform="grow-4"
                />
              </span>
            }
            onClick={() => props.handleRemoving(props.item.PostId)}
          />
        </Box>
        {!props.memberMessaging && (
          <Box
            className="box-default half-width"
            sx={{
              pr: configEntzy.APP_SPACING_SM2X,
              pl: configEntzy.APP_SPACING_XS2X,
            }}
          >
            <Box
              className="box-default shadow-dark rounded"
              sx={{
                p: configEntzy.APP_SPACING_XS,
              }}
            >
              <ActionCore
                {...props}
                room="messaging"
                actionText="Reply"
                actionParams={{
                  inReplyTo: props.item.PostId,
                  inReplyUserId: props.item.UserId,
                }}
                icon={showReply ? iconReplyPost : iconDisabled}
                mini={true}
                fullWidth={true}
                disabled={showReply ? false : true}
                disableElevation={true}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box className="box-default" hidden={!showConfirmDelete}>
        <Box
          className="box-inline left text-left action-pointer"
          sx={{
            p: configEntzy.APP_SPACING_MD,
          }}
          onClick={() => props.handleRemove(props.item)}
        >
          <Typography variant="subtitle2" color="danger.dark">
            {props.canRemove ? (
              <span>
                <FontAwesomeIcon icon={iconDelete} />
                <span>&nbsp;&nbsp;&nbsp;</span>
                <span style={{ opacity: 0.5 }}>Confirm Delete?</span>
              </span>
            ) : (
              <span>&nbsp;</span>
            )}
          </Typography>
        </Box>
        <Box
          className="box-inline right text-right action-pointer"
          sx={{
            p: configEntzy.APP_SPACING_MD,
          }}
          onClick={() => props.handleRemoving(null)}
        >
          <Typography variant="subtitle2">
            {props.canRemove ? (
              <span>
                <FontAwesomeIcon icon={iconClose} />
              </span>
            ) : (
              <span> &nbsp; </span>
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const MessageUser = (props) => {
  return (
    <Box className="box-default">
      <Box
        className="box-inline"
        sx={{
          color: props.light ? "white" : undefined,
        }}
      >
        <UserAvatar
          user={{ identity: props.identity }}
          size="sm"
          light={props.light}
          nameOnly={props.nameOnly}
          nameHideInfo={props.nameHideInfo}
        />
      </Box>
    </Box>
  );
};

export const MessagePost = (props) => {
  const mainContext = useContext(MainContext);

  const user = props.user;
  const loadingId = props.loadingId;
  const canRemove =
    props.manager || props.message.UserId === props.user.identity;

  const [popMessage, setPopMessage] = useState(null);

  const handlePopMessage = async (message) => {
    setPopMessage(message.PostId);
  };
  const handleClosePopMessage = () => {
    setPopMessage(null);
  };

  // hook to load image
  const mediaImage = useMediaImage(
    props.message.ContentImage &&
      props.message.ContentImage !== configEntzy.EMPTY_STRING_SET &&
      props.message.ContentImage !== configEntzy.EMPTY_STRING_SHORTSET
      ? {
          user: user,
          context: mainContext,
          image: {
            id: "event:messages:image:" + props.message.PostId,
            key: props.message.ContentImage,
          },
        }
      : null
  );

  return (
    <Box className="box-default">
      <Box
        className={
          props.message.Category === "runner"
            ? "box-inline left"
            : "box-inline right"
        }
        sx={{
          width: "60%",
        }}
      >
        <Box className="box-default">
          {/* ----- loader ----- */}
          {loadingId === props.message.PostId && (
            <Box className="box-default zero-height text-center">
              <Box
                className="box-default"
                sx={{
                  pt: configEntzy.APP_SPACING_SM,
                }}
              >
                <ActionLoader size="md" color="secondary" />
              </Box>
            </Box>
          )}
          {/* message display */}
          {props.message.PostDeleted ? (
            <Box
              className="box-default bg-black-t10"
              sx={{
                p: configEntzy.APP_SPACING_MD,
                borderRadius: configEntzy.BORDER_SIZE_XL,
              }}
            >
              <Typography variant="subtitle2">
                <em>(message deleted)</em>
              </Typography>
            </Box>
          ) : (
            <Box className="box-default shadow-default bg-white rounded nooverflow">
              <Box
                className="box-default action-pointer"
                onClick={() => handlePopMessage(props.message)}
              >
                {/* list view message text */}
                <Box
                  className="box-default nooverflow"
                  sx={{
                    pt: configEntzy.APP_SPACING_MD,
                    pl: configEntzy.APP_SPACING_MD,
                    pr: configEntzy.APP_SPACING_MD,
                    pb: configEntzy.APP_SPACING_MD2X,
                    maxHeight: configEntzy.AVATAR_SIZE_LG,
                    maskImage: "linear-gradient(180deg, #000 60%, transparent)",
                  }}
                >
                  {props.message.parent && (
                    <Box
                      className="box-default bg-black-t10 rounded"
                      sx={{
                        p: configEntzy.APP_SPACING_SM2X,
                        mb: configEntzy.APP_SPACING_SM2X,
                        textAlign:
                          props.message.Category === "runner"
                            ? "left"
                            : "right",
                      }}
                    >
                      <Box
                        className="box-default"
                        sx={{
                          borderLeft:
                            props.message.Category === "runner"
                              ? "2px solid #888"
                              : "none",
                          borderRight:
                            props.message.Category === "runner"
                              ? "none"
                              : "2px solid #888",
                        }}
                      >
                        <Box className="box-default">
                          <UserAvatar
                            user={{ identity: props.message.parent.UserId }}
                            size="sm"
                            nameOnly={true}
                            nameHideInfo={true}
                          />
                        </Box>
                        <Box className="box-default">
                          <Typography variant="body1" color="black" noWrap>
                            &nbsp;&nbsp;{props.message.parent.ContentData}
                            &nbsp;&nbsp;
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  <Box
                    className="box-default"
                    sx={{
                      p: configEntzy.APP_SPACING_SM,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color="black"
                      sx={{
                        whiteSpace: "pre-line",
                        overflowWrap: "break-word",
                        textAlign:
                          props.message.Category === "runner"
                            ? "right"
                            : "left",
                      }}
                    >
                      {props.message.ContentData}
                    </Typography>
                  </Box>
                </Box>
                {/* preview media image display */}
                {props.message.ContentImage && mediaImage.hydrated && (
                  <Box
                    className="box-default"
                    sx={{
                      p: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    <Box
                      className="box-default bg-black-t10 nooverflow"
                      sx={{
                        borderRadius: configEntzy.BORDER_SIZE_XL,
                      }}
                    >
                      <Box className="box-default text-center">
                        <Box className="box-default zero-height">
                          <Box
                            className="box-default nooverflow"
                            sx={{
                              pt: configEntzy.APP_SPACING_SM2X,
                            }}
                          >
                            <span className="fa-layers fa-fw fa-2x">
                              <FontAwesomeIcon
                                icon={iconCircle}
                                color="black"
                                style={{ opacity: 0.6 }}
                              />
                              <FontAwesomeIcon
                                icon={iconPointer}
                                color="white"
                                transform="shrink-8"
                                className="fa-bounce"
                                style={{
                                  "--fa-animation-duration": "1s",
                                  "--fa-animation-iteration-count": 2,
                                }}
                              />
                            </span>
                          </Box>
                        </Box>
                        <Box
                          className="box-default nooverflow"
                          sx={{
                            borderRadius: configEntzy.BORDER_SIZE_XL,
                            height: configEntzy.AVATAR_SIZE_SM,
                          }}
                        >
                          <img
                            src={mediaImage.images.lg.data}
                            alt={mediaImage.key}
                            style={{
                              width: "100%",
                              height: "auto",
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
              {/* modal message display */}
              <Modal
                open={popMessage === props.message.PostId}
                onClose={handleClosePopMessage}
              >
                <Box
                  className="box-default bg-black-t50 shadow-default rounded"
                  sx={{
                    p: configEntzy.APP_SPACING_MD,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    maxHeight: "80%",
                    maxWidth: "80%",
                  }}
                >
                  <Box className="box-default text-right zero-height">
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      sx={{ overflow: "visible" }}
                    >
                      <FontAwesomeIcon
                        icon={iconClose}
                        size="2x"
                        transform="up-40"
                        onClick={handleClosePopMessage}
                      />
                    </Typography>
                  </Box>
                  {/* message display */}
                  <Box
                    className="box-default bg-white rounded"
                    sx={{
                      p: configEntzy.APP_SPACING_MD2X,
                      maxHeight: props.message.ContentImage
                        ? configEntzy.AVATAR_SIZE_LG
                        : configEntzy.AVATAR_CONTAINER_XL,
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color="black"
                      sx={{
                        whiteSpace: "pre-line",
                        overflowWrap: "break-word",
                      }}
                    >
                      <Linkify>{props.message.ContentData}</Linkify>
                    </Typography>
                  </Box>
                  {/* media images display */}
                  {props.message.ContentImage && mediaImage.hydrated && (
                    <Box
                      className="box-default nooverflow"
                      sx={{
                        pt: configEntzy.APP_SPACING_MD,
                        borderRadius: configEntzy.BORDER_SIZE_XL,
                      }}
                    >
                      <img
                        src={mediaImage.images.lg.data}
                        alt={mediaImage.key}
                        style={{
                          width: "100%",
                          height: "auto",
                          borderRadius: "6px",
                        }}
                      />
                    </Box>
                  )}
                  <Box
                    className="box-default"
                    sx={{
                      pt: configEntzy.APP_SPACING_SM2X,
                    }}
                  >
                    <MessageUser
                      identity={props.message.UserId}
                      nameOnly={props.message.PostDeleted}
                      light={props.message.PostDeleted ? false : true}
                    />
                  </Box>
                </Box>
              </Modal>
              {/* message post controls */}
              <PostControls
                {...props}
                item={props.message}
                showReply={props.showReply}
                canRemove={canRemove}
                removing={props.removing}
                handleRemoving={props.handleRemoving}
                handleRemove={props.handleRemoveMessage}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box
        className={
          props.message.Category === "runner"
            ? "box-inline right"
            : "box-inline left"
        }
        sx={{
          width: "40%",
          pt: configEntzy.APP_SPACING_MD,
        }}
      >
        <MessageUser
          identity={props.message.UserId}
          light={!props.message.PostDeleted}
          nameOnly={props.message.PostDeleted}
          nameHideInfo={true}
        />
      </Box>
    </Box>
  );
};

export const NotificationPost = (props) => {
  const runnerSide = props.source === "runner";
  // const riderSide = props.source === "rider";
  const loadingId = props.loadingId;

  return (
    <Box className="box-default">
      {/* ----- loader ----- */}
      {loadingId === props.notification.NotificationId && (
        <Box className="box-default zero-height text-center">
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_SM,
            }}
          >
            <ActionLoader size="md" color="secondary" />
          </Box>
        </Box>
      )}
      {/* ----- content item ----- */}

      <Box className="box-default bg-white shadow-default rounded nooverflow">
        <Box
          className={
            props.handlePopOpenAction
              ? "box-default action-pointer"
              : "box-default"
          }
          sx={{
            p: configEntzy.APP_SPACING_MD,
            textAlign: runnerSide ? "right" : "left",
            color: "black",
            border: props.notification.MessageRead
              ? "none"
              : configEntzy.BORDER_DASH_OSM,
          }}
          onClick={props.handlePopOpenAction}
        >
          <Typography variant="subtitle2">
            {props.notification.MessageTitle}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              whiteSpace: "pre-line",
              overflowWrap: "break-word",
              mt: configEntzy.APP_SPACING_SM,
            }}
          >
            {props.notification.MessageBody}
          </Typography>
        </Box>
        {/* notification post controls */}
        <PostControls
          {...props}
          item={props.notification}
          notification={true}
          canRemove={true}
          removing={props.removing}
          handleRemoving={props.handleRemoving}
          handleRemove={props.handleRemoveNotification}
        />
      </Box>
    </Box>
  );
};
