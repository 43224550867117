// react core
import { useContext, useState, useEffect } from "react";

// material design
import Box from "@mui/material/Box";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser as iconUser } from "@fortawesome/pro-thin-svg-icons";
// import { faTimes as iconClose } from "@fortawesome/pro-thin-svg-icons";

// entzy config models and components
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { ActionBoxButton } from "components/utils/common/CommonButtons";

function MemberContacts(props) {
  const mainContext = useContext(MainContext);
  const [contactList, setContactList] = useState([]);

  // generate user list from message notifications
  useEffect(() => {
    const userList = [];
    if (mainContext.state.notifications.data.messages.length > 0) {
      mainContext.state.notifications.data.messages.forEach((message) => {
        const messageTitle = message.MessageTitle;
        const messageTitleArray = messageTitle.split(" ");
        messageTitleArray.forEach((word) => {
          if (word.charAt(0) === "@") {
            if (!userList.includes(word)) {
              userList.push(word);
            }
          }
        });
      });
      setContactList(userList);
    }
  }, [mainContext.state.notifications.data.messages]);

  return (
    <Box className="box-default">
      {contactList.map((name) => {
        return (
          <Box
            key={name}
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_MD,
            }}
          >
            <ActionBoxButton
              text={name}
              textNoCasing={true}
              bgColor="secondary.main"
              startIcon={<FontAwesomeIcon icon={iconUser} />}
              onClick={() => props.setMemberName(name.replace("@", ""))}
            />
          </Box>
        );
      })}
    </Box>
  );
}

export default MemberContacts;
