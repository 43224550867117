// react core
// import { useContext } from "react";

// rich text editor with html and links
import { convertToHTML } from "draft-convert";
import { EditorState, convertFromRaw } from "draft-js";
import DOMPurify from "dompurify";
import linkifyHtml from "linkify-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// entzy context and components
// import configEntzy from "components/config/ConfigEntzy";

function WidgetRichText(props) {
  const content = props.content;

  const getConvertedContent = (contentData) => {
    let contentState,
      editorState,
      convertedContent,
      sanitizedHtml,
      linkifyOptions;
    try {
      contentState = convertFromRaw(JSON.parse(contentData));
      editorState = EditorState.createWithContent(contentState);
      convertedContent = convertToHTML(editorState.getCurrentContent());
    } catch (error) {
      convertedContent = contentData;
    }
    sanitizedHtml = DOMPurify.sanitize(convertedContent);
    sanitizedHtml = sanitizedHtml.replace(/<p><\/p>/g, "<p>&nbsp;</p>");
    linkifyOptions = {
      target: "_blank",
      rel: "noopener noreferrer",
    };
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: linkifyHtml(sanitizedHtml, linkifyOptions),
        }}
      />
    );
  };

  return (
    <Box className="box-default text-paragraph text-left">
      <Typography variant="subtitle2">
        <div>{getConvertedContent(content)}</div>
      </Typography>
    </Box>
  );
}

export default WidgetRichText;
