// react core
import { useContext, useState, useEffect } from "react";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// entzy context
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { TextTag } from "components/utils/common/CommonTags";
import { ActionBoxButton } from "components/utils/common/CommonButtons";
// import { ActionLoader } from "components/utils/common/CommonLoaders";

// entzy components
import MemberMessagingSettings from "./MemberMessagingSettings";

// fonts and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell as iconNotifications } from "@fortawesome/pro-duotone-svg-icons";
import { faPlus as iconNew } from "@fortawesome/pro-regular-svg-icons";

function MemberMessagingSelector(props) {
  const mainContext = useContext(MainContext);
  const user = props.user;
  const roomArea = props.roomArea;
  const roomName = props.roomName;
  const roomId = props.roomId;
  const memberName = props.memberName ? props.memberName.toLowerCase() : "";

  const [expanded, setExpanded] = useState(false);
  const [contactList, setContactList] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const notificationsActive =
    mainContext.state.memberMessaging[roomId] &&
    mainContext.state.memberMessaging[roomId].settingsHydrated &&
    user.name !== memberName &&
    (mainContext.state.memberMessaging[roomId].settingsData.mail ||
      mainContext.state.memberMessaging[roomId].settingsData.push);

  const isAiRoom = roomName.startsWith("ai");

  // generate user list from message notifications and contacts
  useEffect(() => {
    const userList = [];
    if (mainContext.state.notifications.data.messages.length > 0) {
      mainContext.state.notifications.data.messages.forEach((message) => {
        const messageTitle = message.MessageTitle;
        const messageTitleArray = messageTitle.split(" ");
        messageTitleArray.forEach((word) => {
          if (word.charAt(0) === "@") {
            word = word.replace("@", "");
            if (!userList.includes(word)) {
              userList.push(word);
            }
          }
        });
      });
      mainContext.state.interaction.users.forEach((contact) => {
        if (
          contact.identity &&
          contact.identity !== user.identity &&
          !userList.includes(contact.name)
        ) {
          userList.push(contact.name);
        }
      });
      setContactList(userList);
    }
  }, [
    mainContext.state.notifications.data.messages,
    mainContext.state.interaction.users,
    user,
  ]);

  return (
    <Box className="box-default">
      {mainContext.state.memberMessaging[roomId] && (
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_MD,
          }}
        >
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            disableGutters={true}
            sx={{
              backgroundColor: "white",
              color: "black",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: "black",
                  }}
                />
              }
            >
              <Box className="box-default">
                <Box className="box-default text-left">
                  <TextTag
                    text={
                      <span>
                        &nbsp; @{memberName}
                        &nbsp;
                      </span>
                    }
                    bgColor="primary.dark"
                    color="white"
                    rounded={true}
                    lower={true}
                  />
                  {isAiRoom ? (
                    <TextTag
                      text={<span>&nbsp;A\&nbsp;</span>}
                      bgColor="white"
                      color="black"
                      rounded={true}
                    />
                  ) : (
                    <></>
                  )}
                  {notificationsActive && user.name !== memberName && (
                    <TextTag
                      text={
                        <span>
                          &nbsp;(&nbsp;
                          <FontAwesomeIcon
                            icon={iconNotifications}
                            className="fa-fw"
                          />
                          &nbsp;)&nbsp;
                        </span>
                      }
                      bgColor="white"
                      rounded={true}
                    />
                  )}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {
                // do not show settings for self
                user.name !== memberName && (
                  <Box
                    className="box-default"
                    sx={{
                      mb: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    <MemberMessagingSettings
                      {...props}
                      roomArea={roomArea}
                      roomName={roomName}
                      roomId={roomId}
                      memberName={memberName}
                    />
                  </Box>
                )
              }
              <Box
                className="box-default text-left"
                sx={{
                  pb: configEntzy.APP_SPACING_MD,
                }}
              >
                <Typography variant="subtitle2" noWrap={true}>
                  &nbsp;Other Private Rooms&nbsp;
                </Typography>
              </Box>
              <Box
                className="box-default"
                sx={{
                  pb: configEntzy.APP_SPACING_SM,
                }}
              >
                <Box
                  className="box-default"
                  sx={{
                    mb: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <ActionBoxButton
                    {...props}
                    text={
                      <Box className="box-default">
                        <Typography
                          variant="subtitle2"
                          color="secondary.dark"
                          noWrap={true}
                        >
                          New Chat
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="black"
                          noWrap={true}
                        >
                          <FontAwesomeIcon icon={iconNew} fixedWidth />
                        </Typography>
                      </Box>
                    }
                    color="white"
                    rounded={true}
                    textNoCasing={true}
                    disableElevation={true}
                    onClick={() => {
                      props.setMemberName(null);
                      setExpanded(false);
                    }}
                  />
                </Box>
                <Box
                  className="box-default"
                  sx={{
                    mb: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <ActionBoxButton
                    {...props}
                    text={
                      <Box className="box-default">
                        <Typography
                          variant="subtitle2"
                          color="secondary.dark"
                          noWrap={true}
                        >
                          @{user.name}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="black"
                          noWrap={true}
                        >
                          <span className="content-disabled">
                            Notes to Self
                          </span>
                        </Typography>
                      </Box>
                    }
                    color="white"
                    rounded={true}
                    textNoCasing={true}
                    disableElevation={true}
                    onClick={() => {
                      props.setMemberName(user.name);
                      setExpanded(false);
                    }}
                  />
                </Box>
                {contactList.map((name) => {
                  return (
                    <Box
                      key={name}
                      className="box-default"
                      sx={{
                        mb: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      <ActionBoxButton
                        {...props}
                        text={
                          <Box className="box-default">
                            <Typography
                              variant="subtitle2"
                              color="secondary.dark"
                              noWrap={true}
                            >
                              @{name}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="black"
                              noWrap={true}
                            >
                              {mainContext.state.interaction.users.find(
                                (obj) => obj.name === name
                              ) &&
                              mainContext.state.interaction.users.find(
                                (obj) => obj.name === name
                              ).contact ? (
                                <span className="content-disabled">
                                  &nbsp;Contact&nbsp;
                                </span>
                              ) : (
                                <span className="content-disabled">
                                  &nbsp;Interaction&nbsp;
                                </span>
                              )}
                            </Typography>
                          </Box>
                        }
                        color="white"
                        rounded={true}
                        textNoCasing={true}
                        disableElevation={true}
                        selected={name === memberName}
                        onClick={() => {
                          props.setMemberName(name);
                          setExpanded(false);
                        }}
                      />
                    </Box>
                  );
                })}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </Box>
  );
}

export default MemberMessagingSelector;
